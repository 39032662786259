import {
  ErrorCodes,
  Middleware,
  ModelError,
  ResponseContext,
} from '../../client'
import { LanguageKey } from '../stores/useLanguage'

export class ApiError extends Error {
  public error: ModelError
  public message: LanguageKey

  constructor(error: { error: ModelError }) {
    const message = error?.error
      ? ApiError.__getMessage(error.error)
      : 'general.technicalError'
    super(message)
    this.message = message
    this.error = error?.error
  }

  static __getMessage(error: ModelError): LanguageKey {
    switch (error?.code) {
      case ErrorCodes.InvalidUserOrEmail:
        return 'accountRelated.incorrectLogin'
      case ErrorCodes.RequestValidationError:
        return 'accountRelated.validationError'
      case ErrorCodes.TechnicalError:
        return 'general.technicalError'
      case ErrorCodes.DisplaynameAlreadyExists:
        return 'accountRelated.displaynameAlreadyExists'
      case ErrorCodes.EmailAlreadyExists:
        return 'accountRelated.emailAlreadyExists'
      case ErrorCodes.InvalidCurrentPassword:
        return 'accountRelated.passwordIncorrect'
      case ErrorCodes.UserAlreadyModerator:
        return 'community.moderatorAlreadyExists'
      default:
        return 'general.technicalError'
    }
  }
}

export function getErrorMessage(e: unknown): LanguageKey {
  return e instanceof ApiError ? e.message : 'general.technicalError'
}

export const errorHandler: Middleware = {
  post: async (context: ResponseContext) => {
    if (context.response.status >= 400) {
      try {
        const { error } = (await context.response.json()) as {
          error: ModelError
        }

        throw new ApiError({ error })
      } catch (error) {
        const customError = error instanceof ApiError ? error.error : undefined

        throw new ApiError({
          error: customError || {
            code: ErrorCodes.TechnicalError,
            message: JSON.stringify(error),
          },
        })
      }
    }
  },
}
