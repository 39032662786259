/** The first section of routes (this shows that these are not channels) */
const RESERVED_WORDS = {
  sportEvents: 'sport-events',
  profile: 'profile',
  termsOfUse: 'terms-of-use',
  contactUs: 'contact-us',
  streams: 'streams',
  following: 'following',
  communityGuidelines: 'community-guidelines',
  termsOfService: 'terms-of-service',
  dmcaPolicy: 'dmca-policy',
  privacyPolicy: 'privacy-policy',
  embed: 'embed',
  howToEmbed: 'how-to-embed',
  callback: 'callback',
  categories: 'categories',
  username: 'username',
  auth: 'auth',
  community: 'community',
  moderators: 'moderators',
  feeds: 'feeds',
}

export const routes = {
  home: '/',
  termsOfUse: `/${RESERVED_WORDS.termsOfUse}`,
  contactUs: `/${RESERVED_WORDS.contactUs}`,
  sportEvents: `/${RESERVED_WORDS.sportEvents}`,
  categories: (group?: string, category?: string, subcategory?: string) => {
    let path = `/${RESERVED_WORDS.categories}`
    if (group) {
      path += `/${group}`
      if (category) {
        path += `/${category}`
        if (subcategory) {
          path += `/${subcategory}`
        }
      }
    }
    return path
  },
  community: {
    moderators: `/${RESERVED_WORDS.community}/${RESERVED_WORDS.moderators}`,
  },
  streams: {
    list: (page: string | number) => `/${RESERVED_WORDS.streams}/${page}`,
    following: `/${RESERVED_WORDS.following}/`,
  },
  feeds: `/${RESERVED_WORDS.feeds}`,
  auth: {
    callback: `/${RESERVED_WORDS.callback}`,
    username: `/${RESERVED_WORDS.auth}/${RESERVED_WORDS.username}`,
  },
  profile: `/${RESERVED_WORDS.profile}`,
  profileRoutes: {
    myStream: `/${RESERVED_WORDS.profile}/my-stream`,
    myProfile: `/${RESERVED_WORDS.profile}/my-profile`,
    subscriptions: `/${RESERVED_WORDS.profile}/subscriptions`,
    achievements: `/${RESERVED_WORDS.profile}/achievements`,
    streamKey: `/${RESERVED_WORDS.profile}/stream-key`,
    badges: `/${RESERVED_WORDS.profile}/badges`,
    security: `/${RESERVED_WORDS.profile}/security`,
    emotes: `/${RESERVED_WORDS.profile}/emotes`,
    deleteAccountPopup: (location: string) =>
      `${location}?popup=delete-account`,
  },
  channel: {
    ...getChannelPaths('chat', (slug: string) => `/chat/${slug}`),
    ...getChannelPaths('home', (slug: string) => `/${slug}`),
    ...getChannelPaths(
      'video',
      (slug: string, videoId: string) => `/${slug}/videos/${videoId}`
    ),
  },
  communityGuidelines: `/${RESERVED_WORDS.communityGuidelines}`,
  termsOfService: `/${RESERVED_WORDS.termsOfService}`,
  dmcaPolicy: `/${RESERVED_WORDS.dmcaPolicy}`,
  privacyPolicy: `/${RESERVED_WORDS.privacyPolicy}`,
  embed: { ...getChannelPaths('embed', (slug: string) => `/embed/${slug}`) },
  howToEmbed: `/${RESERVED_WORDS.howToEmbed}`,
}

/**
 * It creates these variants:
 *
 * - fooShort: the function that you give;
 * - fooFull: (slug: string, videoId: string) => `channel/${shortVersion}`;
 * - foo: fooShort or fooFull depending on the slug
 *
 * @param short the function for the short version which is used to create the others
 */
function getChannelPaths<
  TName extends string,
  T extends (slug: string, ...args: string[]) => string,
>(name: TName, short: T) {
  const words = RESERVED_WORDS as Record<string, string>
  return {
    [`${name}Short`]: short,
    [`${name}Full`]: ((slug, ...args) =>
      `/channel${short(slug, ...args)}`) as T,
    [name]: ((slug, ...args) => {
      if (words[slug] !== undefined) return `/channel${short(slug, ...args)}`
      return short(slug, ...args)
    }) as T,
  } as ChannelPaths<TName, T>
}

type ChannelPaths<
  TName extends string,
  T extends (slug: string, ...args: string[]) => string,
> = {
  [K in `${TName}Short` | `${TName}Full` | TName]: T
}
